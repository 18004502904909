const enum Cashback {
  WITH_SUBSCRIPTION = 10,
  WITHOUT_SUBSCRIPTION = 5,
}

export const calculateSum = (halvaDesyatka: boolean, spending: number) => {
  const cashbackRate = halvaDesyatka ? Cashback.WITH_SUBSCRIPTION : Cashback.WITHOUT_SUBSCRIPTION
  const cashbackAmount = Math.floor((spending / 100) * cashbackRate)

  return Math.min(cashbackAmount, 5000)
}
