import React, { ChangeEvent, useEffect, useState } from "react"
import cn from "classnames"
import Container from "@ecom/ui/components/Container"
import Slider from "@material-ui/core/Slider"
import Checkbox from "@material-ui/core/Checkbox"
import Tooltip from "@material-ui/core/Tooltip"
import { Zoom } from "@material-ui/core"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { StaticImage } from "gatsby-plugin-image"
import Button from "@material-ui/core/Button"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import scrollToPersonalForm from "../../helpers/scrollToPersonalForm"
import { pushToDataLayerOnce } from "../../helpers/pushToDataLayerOnce"
import { calculateSum } from "./helpers/calculate"

import { HalvaIcon } from "./images/HalvaIcon"
import { CheckIcon } from "./images/CheckIcon"
import { EmptyIcon } from "./images/EmptyIcon"
import { Question } from "./images/Question"
import question_icon from "../../images/icons/icon_questionV2.svg"
import close_icon from "../../images/icons/icon_closeV2.svg"

import * as styles from "./newCalculator.module.scss"

interface CalculatorProps {
  subtitle?: React.ReactNode
  darkColor?: boolean
  orderNum?: string
  additionalEventInDataLayer?: boolean
  title?: React.ReactNode
  variant?: "specalAnotherDescr" | "standart" | "inviteFriend" | "btsAuto"
}

const defSubTitle =
  "До 10% кэшбэка с покупок. Воспользуйтесь калькулятором и узнайте, как вернуть до 60 000₽ в год"

const defTitle = <>Рассчитайте выгоду от&nbsp;карты «Халва»</>

export const NewCalculator = ({
  subtitle = defSubTitle,
  title = defTitle,
  darkColor = false,
  orderNum,
  additionalEventInDataLayer = false,
  variant = "standart",
}: CalculatorProps) => {
  const handleClick = () => {
    pushToDataLayer({
      event: "buttonClick",
      name: "cta_calculator",
    })

    scrollToPersonalForm()
  }

  const [spending, setSpending] = useState<number | number[]>(10000)
  const [halvaDesyatka, setHalvaDesyatka] = useState(true)
  const [sum, setSum] = useState(500)

  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const onChangeSpending = (_: ChangeEvent<{}>, value: number | number[]) => {
    if (additionalEventInDataLayer) {
      pushToDataLayerOnce("monthly_spending_calc", {
        event: "buttonClick",
        name: "monthly_spending_calc",
      })
    }
    setSpending(value)
  }

  const onChangeCheckbox = () => {
    if (additionalEventInDataLayer) {
      pushToDataLayerOnce("halva_desyatka_check", {
        event: "buttonClick",
        name: "halva_desyatka_check",
      })
    }
    setHalvaDesyatka((prev) => !prev)
  }

  useEffect(() => {
    setSum(calculateSum(halvaDesyatka, +spending))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [halvaDesyatka, spending])

  return (
    <section
      className={cn(styles.section, darkColor && styles.darkColor, variant && styles[variant])}
      id="calculator"
      data-exclude={orderNum}
    >
      <Container className={styles.container}>
        <div className={styles.calcHeader}>
          <h2 className={styles.head}>{title}</h2>
        </div>
        <p className={styles.descr}>{subtitle}</p>
        <div className={styles.content}>
          <div className={styles.sliderContainer}>
            <p className={styles.sliderDescr}>Сумма покупки у партнеров</p>
            <div className={styles.sliderSum}>{spending.toLocaleString().replace(/,/g, " ")} ₽</div>
            <Slider
              value={spending}
              onChange={onChangeSpending}
              max={100000}
              step={10000}
              classes={{
                root: styles.slider,
                track: styles.track,
                thumb: styles.thumb,
                rail: styles.rail,
              }}
            />
            <div className={styles.checkboxContainer}>
              <div className={styles.checkBoxBlock}>
                <Checkbox
                  classes={{ root: styles.checkbox }}
                  checked={halvaDesyatka}
                  onChange={onChangeCheckbox}
                  color="primary"
                  checkedIcon={<CheckIcon />}
                  icon={<EmptyIcon />}
                />
                <p className={styles.helperText}>
                  С подпиской <span>«Халва.Десятка»</span>
                </p>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div>
                    <Tooltip
                      classes={{
                        tooltip: styles.tooltip,
                      }}
                      PopperProps={{
                        disablePortal: false,
                      }}
                      onClose={handleTooltipOpen}
                      open={open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={
                        <>
                          <img
                            src={question_icon}
                            alt="question"
                            className={styles.hintModalQuestion}
                          />
                          <div className={styles.hintModal}>
                            Подписка «Халва.Десятка» дает дополнительные преимущества вашей карте.
                            Увеличенная рассрочка до 10 месяцев, повышенный кэшбэк до 10%. Первый
                            месяц подписки – 0 рублей, далее – 399 рублей в мес. или 3799 руб. в год
                            (скидка – 20%).
                          </div>
                          <div
                            className={styles.close}
                            onClick={handleTooltipClose}
                            aria-hidden="true"
                          >
                            <img src={close_icon} alt="close" />
                          </div>
                        </>
                      }
                      TransitionComponent={Zoom}
                    >
                      <div
                        className={styles.hintContainer}
                        onClick={handleTooltipOpen}
                        aria-hidden="true"
                      >
                        <Question />
                      </div>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              </div>

              <div className={styles.disclaimer}>
                Предварительный расчет. <br />
                Не является публичной офертой
              </div>
            </div>
          </div>
          <div className={styles.card} id="calculatorCard">
            <StaticImage
              alt="card"
              src="./images/card.png"
              placeholder="blurred"
              formats={["auto", "webp", "avif"]}
            />
            <div className={styles.cardContainer}>
              <div className={styles.cardHeader}>
                <p className={styles.cardHead}>Ваш кэшбэк</p>
                <div className={styles.halvaIcon}>
                  <HalvaIcon />
                </div>
              </div>
              <div className={styles.sum}>
                {sum.toLocaleString().replace(/,/g, " ")} <span>₽</span>
              </div>
              <Button onClick={handleClick} classes={{ root: styles.btn }}>
                Оформить карту
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
